html {
    font-family: 'Arial', 'San Francisco', 'Roboto', 'Meiryo',
        'Hiragino Kaku Gothic', 'Noto Sans CJK JP' !important;
    user-select: none;
    overflow: hidden;

    --bs-font-sans-serif: 'Arial', 'San Francisco', 'Roboto', 'Meiryo',
        'Hiragino Kaku Gothic', 'Noto Sans CJK JP' !important;
    --bs-link-color: #000 !important;
    --bs-link-hover-color: #999 !important;
    height: -webkit-fill-available;
}

@media screen and (max-width: 822px) {
    html {
        font-family: 'Arial', 'San Francisco', 'Roboto', 'Meiryo',
            'Hiragino Kaku Gothic', 'Noto Sans CJK JP' !important;
        user-select: none;
        overflow: hidden;

        --bs-font-sans-serif: 'Arial', 'San Francisco', 'Roboto', 'Meiryo',
            'Hiragino Kaku Gothic', 'Noto Sans CJK JP' !important;
        --bs-link-color: #000 !important;
        --bs-link-hover-color: #999 !important;
    }
}

.modal {
    --bs-modal-width: 600px !important;
}

.App {
    text-align: center;
}

.top_bar {
    max-height: 95px;
    border-bottom: 5px solid #5fc7bc;
    padding: 0 !important;
}

.container-fluid {
    text-align: left;
    justify-content: normal !important;
}

.navbar .navbar-brand span {
    font-size: 1.5vw;
}

.top_logo {
    margin: 7px 0;
    width: 185px;
}

.top_application_name {
    padding-top: 5px;
    padding-left: 15px;
    font-size: 28px;
    letter-spacing: 3px;
}

.top_floor_name {
    padding-top: 5px;
    font-size: 25px;
}

.navbar.navbar-user .container-fluid {
    padding-left: 0;
    padding-right: 0;
    border-bottom: 5px solid #5fc7bc;
}

.navbar.navbar-user .navbar-brand img {
    height: 24px;
    width: auto;
}

.navbar.navbar-user .navbar-brand span {
    margin-top: -6px;
    font-size: 15px;
}

.nav_tab {
    color: white !important;
}

.nav {
    --bs-nav-link-disabled-color: #8f8f8f !important;
}

.tabs {
    color: white;
}

.tabs span {
    margin: 10%;
}

.tabs span:hover {
    cursor: pointer;
    opacity: 0.6;
    /* ボタンの透明度を60%にする */
}

.topbar_menu {
    text-align: center;
    justify-content: center;
    padding-bottom: 5px;
    font-size: 20px;
}

.topbar_menu .nav-item {
    height: 40px;
    padding-top: 2px;
}

.topbar_menu .nav-item .nav-link {
    width: 200px;
    cursor: pointer;
    padding-bottom: 0 !important;
}

.topbar_menu .nav-item .nav-link.disabled {
    border-bottom: 5px solid #5fc7bc;
}

.topbar_menu .nav-item:last-child {
    width: 200px;
    cursor: pointer;
    position: absolute;
    right: 0;
}

.topbar_menu .nav-item:last-child img {
    padding-bottom: 5px;
}

.topbar_menu .nav-item:last-child:hover {
    background-color: #fff;
}

.App-logo {
    margin: 100px 0;
    pointer-events: none;
}

/*

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/

/* ---------------------------------------------------
    BOOTSTRAP BUTTON COLOR
----------------------------------------------------- */
.btn-primary {
    background-color: #ff8200 !important;
    border-color: #ff8200 !important;
}

.btn-primary:hover {
    background-color: #f59a11 !important;
    border-color: #f59a11 !important;
}

.btn-primary:focus {
    box-shadow: 0 0 0 0.25rem rgb(255 130 0 / 25%) !important;
}

.btn-secondary {
    background-color: #595b61 !important;
    border-color: #595b61 !important;
}

.btn-secondary:hover {
    background-color: #414141 !important;
    border-color: #414141 !important;
}

.btn-success {
    background-color: #5fc7bc !important;
    border-color: #5fc7bc !important;
}

.btn-success:hover {
    background-color: #38b8ab !important;
    border-color: #38b8ab !important;
}

.btn-outline-success {
    border-color: #5fc7bc !important;
    color: #000000 !important;
}

.btn-outline-success:hover {
    background-color: #38b8ab !important;
    border-color: #38b8ab !important;
    color: #ffffff !important;
}

.btn-success:focus {
    background-color: #23a598 !important;
    border-color: #23a598 !important;
    color: #ffffff !important;
    box-shadow: 0 0 0 0.25rem rgb(95 199 188 / 25%);
}

.btn-success.active,
.btn-outline-success.active {
    background-color: #5fc7bc !important;
    border-color: #5fc7bc !important;
    color: #ffffff !important;
}

.form-check-input:checked {
    background-color: #5fc7bc !important;
    border-color: #5fc7bc !important;
}

.form-select:focus,
.form-control[type='number']:focus,
.form-control[type='text']:focus,
.form-control[type='select']:focus,
.form-control[type='radio']:focus,
.form-control[type='time']:focus,
.form-control[type='date']:focus,
.form-control[type='password']:focus,
.form-check-input[type='checkbox']:focus {
    border-color: #5fc7bc !important;
    box-shadow: 0 0 0 0.25rem rgb(95 199 188 / 25%) !important;
}

/* SVGファイル 色変更(#5fc7bc)*/
.svg_success {
    filter: invert(71%) sepia(67%) saturate(282%) hue-rotate(124deg)
        brightness(100%) contrast(88%);
}

.btn-close {
    width: 30px !important;
    height: 30px !important;
    position: absolute;
    right: -5px;
    top: -5px;
    padding-left: 10px !important;
    background-image: url('img/close.svg') !important;
    background-color: #000000 !important;
    border-radius: 50px !important;
    opacity: 1 !important;
}

.btn-close:focus {
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%) !important;
}

.volume_up,
.volume_down {
    display: inline-block;
    vertical-align: middle;
    width: 64px;
    height: 64px;
}

.volume_up {
    background-image: url('img/volume_up.svg');
}

.volume_down {
    background-image: url('img/volume_down.svg');
}

.volume_up:active {
    background-image: url('img/volume_up-press.svg');
    background-repeat: no-repeat;
}

.volume_down:active {
    background-image: url('img/volume_down-press.svg');
    background-repeat: no-repeat;
}

/* ---------------------------------------------------54a77d
    CONTENT STYLE
----------------------------------------------------- */
.contents_area {
    width: 1920px;
    display: flex;
}

.content {
    padding: 10px;
}

.contents_user {
    max-width: 850px !important;
    font-size: 16px;
    /* Chromeのバージョンによっては svh が使えないため、先に vh での指定を行う */
    max-height: 100vh;
    max-height: 100svh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 25px;
}

/* ----------------------------------------------------
    MAP DROP ZONE (width：1280px固定）
----------------------------------------------------- */
.map_drop {
    border: 1px solid;
    width: 1280px;
    height: 720px;
    font-size: 50px;
    padding: 30vh;
    text-align: center;
    background-repeat: no-repeat;
    background-size: 100%;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
}

.map_div {
    width: 1280px;
    height: 720px;
    text-align: left;
    background-color: #ccc;
    overflow: hidden;
}

.map_div img {
    width: 100%;
    height: auto;
    object-position: top left;
    object-fit: cover;
}

.area {
    margin: 0 auto;
}

.area_header {
    margin-top: 10px;
    height: 50px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    font-size: 20px;
}

.area_header input {
    display: inline;
    width: 100px;
    font-size: 20px;
}

.area_header .floor_header {
    padding: 5px 0;
}

.area_header .speaker_header {
    padding: 10px 0;
}

.area_header .group_header {
    text-align: left;
}

.area_header .group_header img {
    padding-bottom: 5px;
}

/* ----------------------------------------------------
    MODAL COMMON
----------------------------------------------------- */
.modal-header {
    border-bottom: none !important;
    padding-bottom: 10px !important;
}

.modal-header .btn-close {
    z-index: 1;
}

.modal-body {
    font-size: 18px;
}

.modal-footer {
    border-top: none !important;
}

.modal-footer button {
    font-size: 20px !important;
}

/* ----------------------------------------------------
    MODAL_MESSAGE FOOTER
----------------------------------------------------- */

.modal-footer.with_cancel button {
    margin: auto;
    width: 45%;
}

.modal-footer.without_cancel button {
    margin: auto;
    width: 75%;
}

/* ----------------------------------------------------
    MODAL_NEW_FLOOR
----------------------------------------------------- */
.modal:has(.modal_new_floor) {
    --bs-modal-width: 90% !important;
    font-size: 20px;
}

/* ----------------------------------------------------
    MODAL_LOAD_FLOOR
----------------------------------------------------- */

.modal:has(.modal_load_floor) {
    --bs-modal-width: 40% !important;
    font-size: 20px;
}

.modal_load_floor .modal-body {
    display: block;
    padding: 20px 30px;
    overflow-y: scroll;
    max-height: 800px;
}

.modal_load_floor .modal-body::-webkit-scrollbar {
    width: 10px;
}

.modal_load_floor .modal-body::-webkit-scrollbar-thumb {
    background: #cacaca;
    border-radius: 3px;
}

.modal_load_floor .modal-body::-webkit-scrollbar-thumb:hover {
    background: #aaaaaa;
}

.div_load_floor_radio button {
    margin: 5px;
    padding: 15px 10px;
    font-size: 20px;
}

.div_load_floor_radio button.active_radio {
    background-color: #313131 !important;
}

.modal_load_floor button.floor_name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* ----------------------------------------------------
    MODAL_SAVE_FLOOR
----------------------------------------------------- */
.save_floor_btn {
    font-size: 25px !important;
    width: 200px;
}

.modal:has(.modal_save_floor) {
    --bs-modal-width: 20% !important;
}

.modal_save_floor .modal-body {
    padding: 0 20px 20px 20px;
}

/* ----------------------------------------------------
    MODAL_GROUP
----------------------------------------------------- */

.modal:has(.modal_add_group) {
    --bs-modal-width: 40% !important;
    font-size: 20px;
}

.modal_add_group .modal-body {
    display: flex;
    padding: 20px 60px;
}

/* ----------------------------------------------------
    MODAL_SPEAKER
----------------------------------------------------- */

.modal:has(.modal_speaker) {
    --bs-modal-width: 50% !important;
    font-size: 20px;
}

.modal_speaker .modal-body {
    padding: 20px 50px;
}

.modal_speaker .row {
    margin-bottom: 10px;
}

.modal_speaker .row button {
    width: 100%;
    height: 45px;
    font-size: 20px;
}

.modal_speaker .check_access_result {
    padding-top: 7px;
    font-size: 20px;
    color: red;
    text-align: center;
}

/* ----------------------------------------------------
    MODAL_SELECT_SOUND_SOURCR
----------------------------------------------------- */
.modal_select_sound_source .modal-body,
.modal_select_stop_time .modal-body {
    height: 65vh;
    overflow-y: auto;
}

.custom_switch {
    padding: 2px;
    width: 100%;
}

.custom_switch input[type='checkbox'] {
    display: none;
}

.custom_switch input[type='checkbox'] + label {
    cursor: pointer;
    display: inline-block;
    box-sizing: content-box;
    position: relative;
    width: 115px;
    height: 35px;
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 30px;
}

.custom_switch input[type='checkbox']:checked + label {
    background-color: #5fc7bc;
    border-color: #5fc7bc;
}

.custom_switch input[type='checkbox'] + label:before {
    content: '';
    position: absolute;
    display: block;
    width: 35px;
    height: 35px;
    /* widthと同じ 上のラベルの高さに揃える */
    left: 0;
    top: 0;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 1px 1px 2px gray;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.custom_switch input[type='checkbox']:checked + label:before {
    -webkit-transform: translateX(230%);
    transform: translateX(230%);
    box-shadow: none;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.custom_switch label {
    transition-duration: 0.2s;
}

.custom_switch label span {
    font-size: 13px;
}

.switch_label {
    font-size: 14px !important;
}

.custom_switch label span.checked {
    color: #fff;
    padding-left: 10%;
}

.custom_switch label span {
    color: #000;
    padding-left: 35%;
    position: absolute;
    top: 7px;
}

.div_degree {
    background-color: #f2f2f2;
    height: 150px;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
    height: 500px;
    margin: 10px;
    padding: 20px;
    border-radius: 15px;
    background-color: #f8f8f8;
    width: 380px;
    color: #000;
    transition: all 0.5s;
    height: calc(100vh - 120px);
}

.sidebar.is-open {
    margin-left: 0;
    transition: 0.5s;
}

.sidebar-header h3 {
    color: #000;
    padding: 1em;
}

.sidebar ul p {
    color: #000;
    padding: 10px;
}

.menu-open {
    background: #f8f8f8;
}

.nav-item:hover {
    color: #000;
    background: #f8f8f8;
}

li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 60%;
    top: 10%;
}

.sidebar-header > span {
    position: relative;
    float: right;
    margin: 0.5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
}

.sidebar_speakers {
    overflow-y: scroll;
    height: 75vh;
}

.sidebar_speakers::-webkit-scrollbar {
    width: 10px;
}

.delete_floor_btn {
    margin-top: 30px;
    width: 100%;
    font-size: 25px !important;
}

.sidebar-load-btn button {
    width: 90%;
    font-size: 25px;
}

.sidebar_groups .nav-item {
    font-size: 25px;
    padding: 10px;
    text-align: left;
    user-select: none;
    display: flex;
    justify-content: space-between;
    word-break: break-all;
}

.sidebar_groups .nav-item:hover {
    border-radius: 10px;
    background-color: #cacaca;
}

.sidebar_groups .nav-item img {
    width: 40px;
    cursor: pointer;
}

.sidebar_groups_items {
    height: calc(100vh - 250px);
    overflow-y: scroll;
    width: 340px;
}

.sidebar_group_header {
    padding-right: 20px !important;
}

.sidebar_groups_items::-webkit-scrollbar {
    width: 10px;
}

.sidebar_groups_items::-webkit-scrollbar-thumb {
    background: #cacaca;
    border-radius: 3px;
}

.sidebar_groups_items::-webkit-scrollbar-thumb:hover {
    background: #aaaaaa;
}

.sidebar_group_btns {
    padding-left: 20px;
}

.sidebar_group_btns button {
    padding: 0 0;
}

.sidebar_group_btns button:active {
    border: none;
}

.sidebar_group_name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.items_group_speaker {
    color: #000;
    padding-left: 50px !important;
    display: flex !important;
}

.sidebar_group_speaker_name {
    padding-top: 5px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.sidebar_group_speaker_btns {
    float: right;
}

.sidebar_group_speaker_btns button {
    padding: 0 0;
}

.add_group_speaker_btn {
    margin: 10px auto;
    width: 80%;
    font-size: 20px !important;
}

.popover-body {
    padding: 10px !important;
}

.popover-body li {
    display: block;
    padding: 10px;
    font-size: 20px;
}

.popover-body li:first-child {
    border-bottom: 1px solid #ccc;
}

.sidebar_schedule_group_label {
    display: flex;
    margin-bottom: 15px;
    padding-left: 20px;

    font-size: 20px;
    font-weight: bold;
    justify-content: space-between;
}

.sidebar_schedule_group_label span {
    padding-top: 3px;
}
.show_speaker_list_btn {
    z-index: 0 !important;
}

.all_select_btn {
    font-weight: bold !important;
    margin-bottom: 15px;
}

.sidebar_schedule_group_items {
    width: 18vw;
    height: calc(100vh - 250px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.sidebar_schedule_group_items::-webkit-scrollbar {
    width: 5px;
}

.sidebar_schedule_group_items::-webkit-scrollbar-thumb {
    background: #cacaca;
    border-radius: 3px;
}

.sidebar_schedule_group_items::-webkit-scrollbar-thumb:hover {
    background: #aaaaaa;
}

.sidebar_scheduler .nav-item {
    font-size: 25px;
    margin-right: 5px;
    padding: 10px;
    text-align: left;
    user-select: none;
    display: flex;
    justify-content: space-between;
}

.sidebar_scheduler .nav-item.menu-open {
    position: sticky;
    top: 0;
}

.sidebar_scheduler .nav-item:hover {
    border-radius: 10px;
    background-color: #cacaca;
}

.sidebar_scheduler .nav-item img {
    height: 40px;
}

.sidebar_scheduler .nav-item span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.group_label {
    width: 280px;
}

.group_speaker_label {
    width: 220px;
}

.sidebar_scheduler_items {
    width: 18vw;
    height: calc(100vh - 250px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.sidebar_scheduler_header {
    margin-right: 2px !important;
}

.sidebar_scheduler_items::-webkit-scrollbar {
    width: 0px;
}

.sidebar_scheduler_items::-webkit-scrollbar-thumb {
    background: #cacaca;
    border-radius: 3px;
}

.sidebar_scheduler_items::-webkit-scrollbar-thumb:hover {
    background: #aaaaaa;
}

.items_scheduler_speaker {
    color: #000;
    padding-left: 75px !important;
}

.sidebar_scheduler_btns input {
    margin-left: 5px !important;
    margin-right: 5px !important;
}

/*---------------------------------------------------
    SIDEBAR_SPEAKER STYLE
-----------------------------------------------------*/
.speaker_type {
    font-size: 22px;
    font-weight: bold;
    border: 1px solid #e9e9e9;
    padding: 20px 10px;
    text-align: right;
    float: right;
    background-color: #fff;
}

.speaker_type:hover {
    background-color: #eee;
}

.speaker_type span {
    margin: 10px;
}

.speaker_type img {
    width: 35px;
}

/*---------------------------------------------------
    SPEAKERS_DROP_ZONE STYLE
-----------------------------------------------------*/
.speaker_drop {
    border: 1px solid;
    width: 1280px;
    height: 720px;
    padding: 30vh;
    text-align: center;
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    overflow: hidden;
}

.group_selected > g > g {
    stroke-width: 1px;
}

.selected {
    border: 4px solid #5fc7bc;
    border-radius: 50%;
}

.speaker_item {
    position: absolute;
    background: rgba(255, 0, 0, 0);
    cursor: 'move';
}

.sound_pressures {
    position: absolute;
    /*opacity: 100%;*/
}

.scheduler_div {
    position: absolute !important;
    top: auto !important;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: #f8f8f8;
    color: #000;
    cursor: auto !important;
    display: flex !important;
}

/* スケジュールバー */

.scheduler_content {
    width: 85%;
    height: 100%;
}

.scheduler_top {
    display: flex;
    justify-content: space-between;
    height: 50px;
    border: 1px solid #d9d9d9;
    border-bottom: none;
    padding: 10px 0;
}

.scheduler_top_left {
    width: 288px;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    padding-left: 20px;
}

.scheduler_top_content {
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
}

.scheduler_top_disp_date {
    position: absolute;
    left: 288px;
    font-size: 20px;
}

.scheduler_top_con_btn {
    color: gray;
    margin: 0 20px;
    cursor: pointer;
}

.scheduler_period_selecter.form-select {
    padding: 0 0 0 10px;
    width: 100px;
    font-size: 18px !important;
    background-position: right 0.5rem center !important;
}

.scheduler_top_right {
    width: 50px;
}

.scheduler_top_right img {
    width: 20px;
    cursor: pointer;
}

.time_set_pop {
    max-width: 350px !important;
}

.time_set_inner {
    padding: 20px 10px 10px 10px;
}

.time_set_content {
    font-size: 18px;
}

.time_set_content p {
    text-align: center;
}

.time_set_content p:last-child {
    margin-bottom: 5px;
}

.time_set_content p select {
    width: 100px;
    margin: auto;
}

.time_set_btns .btn {
    width: 120px;
}

.time_set_btns .btn:first-child {
    margin-right: 20px;
}

.scheduler_main_scroll_div {
    height: 100%;
    overflow-y: scroll;
    direction: rtl;
}

.scheduler_main_scroll_div::-webkit-scrollbar {
    position: absolute;
    left: 300px;
    width: 10px;
}

.scheduler_main_scroll_div::-webkit-scrollbar-thumb {
    background: #cacaca;
    border-radius: 3px;
}

.scheduler_main_scroll_div::-webkit-scrollbar-thumb:hover {
    background: #aaaaaa;
}

.scheduler_main {
    height: 100%;
    border: 1px solid #d9d9d9;
    direction: ltr;
    border-top: none;
    border-left: none;
    overflow-x: none;
    margin-left: 38px;
}

.scheduler_root_header {
    background: #f8f8f8 !important;
    border: none !important;
    position: sticky;
    top: 0;
    z-index: 1;
}

.rct-outer .rct-sidebar {
    border-right: none !important;
}

.rct-scroll {
    overflow-x: hidden !important;
    cursor: default !important;
    border-left: 1px solid #d9d9d9;
}

.rct-horizontal-lines * {
    background: #f8f8f8 !important;
    border-bottom: none !important;
}

.rct-horizontal-lines div:last-child {
    border-bottom: 1px solid #d9d9d9 !important;
}

.rct-vertical-lines * {
    border-left: 1px solid #d9d9d9 !important;
}

.scheduler_date_header {
    border: 1px solid #d9d9d9 !important;
    border-right: none !important;
}

.scheduler_date_header div > div {
    border-bottom: none !important;
    border-left: 1px solid #d9d9d9 !important;
}

.scheduler_date_header .schedule_12hour_header .rct-dateHeader span {
    font-size: 20px;
}

.scheduler_date_header .schedule_24hour_header .rct-dateHeader span {
    font-size: 17px;
}

.scheduler_date_header .schedule_day_header .rct-dateHeader span {
    display: grid;
    width: 100%;
    font-size: 18px;
    padding: 5px 0;
}

.scheduler_date_header .schedule_day_header .rct-dateHeader span::after {
    content: '0　 　 　　　12';
    font-size: 15px;
    font-weight: bold;
    text-align: left;
}

.scheduler_date_header .schedule_time_marking .rct-dateHeader {
    border-left: 1px solid #aaa !important;
}

.scheduler_date_header
    .schedule_time_marking
    .rct-dateHeader:nth-child(12n + 1) {
    border-left: 1px solid black !important;
}

.scheduler_group_header {
    padding-left: 10px;
    font-size: 20px;
    text-align: left;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.rct-sidebar-row:has(.scheduler_group_header) {
    background: #f8f8f8 !important;
    border-bottom: none !important;
}

.tooltip {
    --bs-tooltip-zindex: 100 !important;
    --bs-tooltip-max-width: 300px !important;
    --bs-tooltip-color: #000 !important;
    --bs-tooltip-bg: #fff !important;
}

.tooltip-inner {
    width: fit-content !important;
    max-width: 100% !important;
    text-align: left !important;
    border: 1px solid #ccc;
    z-index: 0;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
    z-index: 0 !important;
}

.hide-replaced.ws-inputreplace {
    display: none !important;
}

.scheduler_right_side {
    width: 100%;
    height: 100%;
    padding: 10px 0;
    border-top: 1px solid #d9d9d9;
}

.scheduler_right_side > p {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 10px;
    padding-left: 10px;
}

.scheduler_right_side .row button {
    width: 45px;
    height: 50px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 10px #b9b9b9;
    background: white;
}

.scheduler_right_side .row {
    margin-bottom: 20px;
}

.scheduler_right_side .row p {
    margin-bottom: 5px;
}

.scheduler_right_side .row .speaker_icon {
    padding: 0 10px;
}

.scheduler_right_side .sound_pressure_label {
    margin-left: 10px;
    padding-top: 5px;
    font-size: 20px;
}

.img_btn {
    cursor: pointer;
}

.mute_alert_div {
    transition: 0.3s;
    position: absolute;
    top: 95px;
    left: 0;
    width: 100%;
    border-top: 7px solid #e8131e;
}

.mute_alert_div div {
    margin: auto;
    background-color: #e8131e;
    font-size: 18px;
    color: white;
    width: 400px;
    border-end-start-radius: 10px;
    border-end-end-radius: 10px;
}

.mute_off_alert_div {
    transition: 0.3s;
    opacity: 0;
}

.sound_pressure .checked.switch_label {
    letter-spacing: 5px;
    padding: 0 30px 10px 0;
}

.sound_pressure .switch_label {
    font-size: 22px !important;
    font-weight: bold;
    letter-spacing: 5px;
}

.sound_pressure label span {
    top: 2px;
    left: 5px;
}

.sound_pressure label span.checked {
    left: 20px;
}

.custom_switch.sound_pressure input[type='checkbox']:checked + label {
    background-color: #5fc7bc;
    border-color: #5fc7bc;
}

.scheduler_right_side .test_sound {
    text-align: center;
    padding: 0;
}

.scheduler_right_side .test_sound span {
    width: 80%;
    font-size: 20px;
    font-weight: bold;
}

.scheduler_right_side .test_sound span.test_disabled {
    background-color: #8f8f8f !important;
    border: 1px solid #8f8f8f !important;
}

/* スケジュール詳細設定画面 */
.schedule_setting_div {
    position: absolute !important;
    top: auto !important;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0px;
    z-index: 102;
    background-color: #f8f8f8;
    color: #000;
    cursor: auto !important;
    overflow: hidden;
    overflow-y: auto;
}

.schedule_settings {
    width: 1200px;
    height: 100%;
    text-align: left;
    margin: auto;
    padding-top: 10px;
    font-size: 18px;
}

.schdule_detail {
    text-decoration: underline;
}

.schedule_setting_content {
    width: 100%;
    max-height: 100%;
}

.schedule_setting_content .row {
    margin-bottom: 10px;
}

.invalid_period {
    border: 1px solid #dc3545 !important;
}

.smooth {
    -webkit-transition: all 0.3s ease !important;
    transition: all 0.3s ease !important;
}

.login {
    width: 25%;
}

.fullSizeButton {
    width: 100% !important;
}

.schedule_speakers {
    display: flex;
}

.setting_speaker_name {
    overflow: hidden;
    overflow-y: scroll;
    max-height: 54px;
    width: 90%;
}

.setting_speaker_name::-webkit-scrollbar {
    width: 5px;
}

.setting_speaker_name::-webkit-scrollbar-thumb {
    background: #e9e9e9;
    border-radius: 3px;
}

.setting_speaker_name div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.schedule_setting_content .row {
    min-height: 44px;
}

.schedule_setting_content .row input[type='text'],
.schedule_setting_content .row input[type='time'],
.schedule_setting_content .row input[type='date'] {
    min-height: 44px;
}

.schedule_setting_switch {
    height: 44px;
}

.custom_switch.schedule_setting_switch label {
    padding: 0 10px;
}

.custom_switch.schedule_setting_switch label span.checked {
    color: #fff;
    padding-left: 15px;
}

.custom_switch.schedule_setting_switch label span {
    padding-left: 30px;
}

.custom_switch.schedule_setting_switch
    input[type='checkbox']:checked
    + label:before {
    -webkit-transform: translateX(285%);
    transform: translateX(285%);
}

.color_dropdown .dropdown-toggle::after {
    content: none;
}

.color_pallet {
    padding: 10px 20px 0 20px;
    text-align: center;
    width: 400px;
    height: fit-content;
}

.color_pallet .row {
    height: 40px;
    min-height: 40px;
}

.color_pallet .row .col-sm-2 div {
    width: 100%;
    height: 100%;
    border-radius: 50px;
}

.color_selected {
    width: 30px;
    display: flex;
    border-radius: 50px;
}

.color_checked {
    background-image: url('./img/check.svg') !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.setting_btns {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
}

.setting_btns button {
    width: 25%;
    margin-right: 2em;
}

.form-select,
.form-control {
    font-size: 20px !important;
}

.schedule_setting_repeat_area .col {
    flex: 0 0 !important;
}

.scheduleCheckBox,
.scheduleWeekCheckBox {
    text-align: center;
}

.scheduleCheckBox input[type='checkbox'],
.scheduleWeekCheckBox input[type='checkbox'] {
    width: 1.5em;
    height: 1.5em;
    margin: 0;
}

.scheduleCheckBox label {
    width: 2em;
}

.scheduleWeekCheckBox label {
    width: 2em;
}

.repeat_every_week {
    display: flex;
    width: 380px !important;
    padding: 5px 10px;
}

.repeat_every_week .scheduleCheckBox {
    margin: 0 10px;
}

.repeat_pick_date {
    margin-left: 41.6%;
    margin-right: 5%;
    padding: 5px 10px 0 10px;
}

.repeat_pick_day {
    margin-left: 41.6%;
    margin-right: 22%;
    padding: 5px 10px 0 10px;
}

.repeat_pick_date.is-invalid,
.repeat_pick_day.is-invalid,
.custom_date_list.is-invalid,
.repeat_every_week.is-invalid {
    border: 1px solid #dc3545;
    border-radius: 5px;
    margin-top: 5px;
}

.custom_date_list {
    background: #ffffff;
    width: 100%;
    height: 33vh;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 5px;
    overflow-y: scroll;
}

.custom_date_list::-webkit-scrollbar {
    width: 5px;
}

.custom_date_list::-webkit-scrollbar-thumb {
    background: #cacaca;
    border-radius: 3px;
}

.custom_date_list::-webkit-scrollbar-thumb:hover {
    background: #aaaaaa;
}

.custom_date_list .list-group-item {
    border-radius: 0 !important;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    display: flex;
    justify-content: space-between;
}

.custom_date_delete {
    color: #aaaaaa;
    cursor: pointer;
}

.invalid-feedback.repeat_options_err {
    margin-left: 41.6% !important;
}

.row:has(.invalid-feedback) {
    margin-bottom: 0;
}

.invalid-feedback {
    margin: 0 !important;
    font-size: 15px !important;
}

.period_err_message {
    margin-left: 16.6% !important;
}

.time_with_tilde {
    display: flex;
}

.time_with_tilde::after {
    content: '～';
    padding: 5px 0 0 25px;
}

.time::after {
    content: '　';
    padding: 0 0 0 25px;
}

.errorMessage {
    height: 180px;
    white-space: pre-line;
    color: red;
}

.version-text {
    margin-top: 15px;
    color: #CCCCCC;
}

.start_time_input,
.end_time_input {
    background-image: url('img/time.svg') !important;
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: 105px;
}

.start_time_input::-webkit-calendar-picker-indicator,
.end_time_input::-webkit-calendar-picker-indicator {
    display: none;
}

.start_time_pop,
.end_time_pop {
    border-radius: 0 !important;
}

.start_time_inner,
.end_time_inner {
    width: 137px;
    max-width: 137px;
    max-height: 200px;
    overflow-y: scroll;
}

.start_time_inner p,
.end_time_inner p {
    font-size: 20px;
    margin-bottom: 0;
    padding: 5px 15px;
    border-bottom: 1px solid #d9d9d9;
}

.start_time_inner p:hover,
.end_time_inner p:hover,
.start_time_inner p.active,
.end_time_inner p.active {
    color: #ffffff;
    background: #1e90ff;
}

/* react-calendar Calendar.css */
.react-calendar {
    width: 350px;
    max-width: 100%;
    height: 33vh;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 5px;
    /* font-family: Arial, Helvetica, sans-serif; */
    line-height: 1.125em;
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 5px;
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}

.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
    font-size: 1.2em;
    padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 1em 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus,
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__tile--hasActive {
    background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}

.react-calendar__tile--active {
    background: #006edc;
    color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}

.fullSizeButton {
    width: 100% !important;
}

.icon_trash {
    width: 5%;
}

.form_with_valid {
    height: 75px;
}

.cursor_pointer {
    cursor: pointer;
}

abbr[title] {
    text-decoration: none !important;
}

.react-calendar__month-view__weekdays__weekday--weekend {
    color: blue;
}

.react-calendar__month-view__weekdays__weekday--weekend:first-child {
    color: red;
}

.react-calendar__month-view__days__day--weekend {
    color: none;
}

.modal_scheudle_choice {
    max-width: 600px !important;
}

.modal_scheudle_dulplicate_choice {
    max-width: 700px !important;
}

.modal_duplicate_choice_form {
    margin: 30px 5px 5px 5px;
}

.modal_sound_test {
    transition: 2s;
}

.sound_testing .modal-content {
    height: 400px;
    text-align: center;
}

.sound_testing .modal-content .modal-body {
    padding-top: 80px;
}

.sound_testing .modal-content .modal-body img {
    margin-right: 20px;
}

.recycle_bin {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.recycle_bin > g > path {
    fill: #5fc7bc;
}

.speaker_recycle {
    width: 50px;
    height: 50px;
}

/* ----------------------------------------------------
    LOADING STYLE
----------------------------------------------------- */
.loading {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1051;
    display: block;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.loading-backdrop {
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.spinner-border.text-primary {
    width: 150px;
    height: 150px;
    color: #5fc7bc !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

/* ----------------------------------------------------
    iframe Style
----------------------------------------------------- */
.iframe_wrapper {
    position: relative;
    width: 100%;
    /* 画面サイズからヘッダとボタン2つとマージンを引いた高さを指定 */
    /* (Chromeのバージョンによっては svh が使えないため、先に vh での指定を行う */
    height: calc(100vh - (55px + 46px + 46px) - 5vh);
    height: calc(100svh - (55px + 46px + 46px) - 5svh);
}

.iframe_wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/*---------------------------------------------------
    USER_MAP STYLE
-----------------------------------------------------*/
.area_image_card {
    position: relative;
}

.area_image {
    position: relative;
    width: 100%;
}

.pin_icon {
    position: absolute;
}

.react-transform-wrapper,
.react-transform-component,
.react-transform-element {
    width: 100% !important;
    height: 100% !important;
}

.volume_edit {
    margin-top: 20px !important;
}

.sound_source_btn {
    width: 60vw;
    max-width: calc(850px - 30%);
    text-align: left;
    border: solid 1px;
    border-radius: 5px;
    background-color: white!important;
    color: black!important;
    border-color: black!important;
    height: 2rem;
    padding-left: 15px;
}

.card.user_map_area {
    padding: 2px;
}

.mt-5.sound_control_btn {
    margin-top: 1.5rem !important;
}

.user_info_text {
    height: 1.5rem !important;
}

.user_info_text label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

.input-range {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    height: 5px;
    width: 100%;
}

::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #5fc7bc;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.disablePlay path {
    fill: gray;
}

/*---------------------------------------------------
    LOG_STYLE
-----------------------------------------------------*/
.base_log {
    width: 1400px;
    margin: auto;
}

.log_header {
    width: 100%;
    height: 50px;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
}

.log_search_date {
    display: flex;
}

.log_search_date label {
    padding-top: 5px;
    font-size: 20px;
}

.log_search_date input {
    font-size: 18px !important;
}

.label_log_period_start {
    margin-left: 10px;
}

.label_log_period_end {
    margin: 0 20px;
}

.log_update_button {
    width: 200px;
    font-size: 18px !important;
}
.log_table {
    margin-top: 10px;
}

.log_table thead {
    background-color: #5fc7bc;
}

.log_table_header {
    text-align: center;
    color: #fff;
    font-size: 18px;
}

th:has(.log_table_header) {
    position: relative;
    border-right: 1px solid #d9d9d9;
    background-color: transparent!important;
}

th:has(.log_table_header) span {
    cursor: pointer;
}

th:has(.log_table_header) span.active {
    color: #595b61;
}

th:has(.log_table_header) span:nth-child(2) {
    position: absolute;
    right: 5px;
}

th:has(.log_table_header) span:nth-child(1) {
    position: absolute;
    right: 20px;
}

th:has(.log_table_header):last-child {
    border-right: 0;
}

th:has(.log_table_header.log_date) {
    width: 150px;
}

th:has(.log_table_header.log_time) {
    width: 150px;
}

th:has(.log_table_header.log_account) {
    width: 250px;
}

th:has(.log_table_header.log_speaker) {
    width: 250px;
}

th:has(.log_table_header.log_identifier) {
    width: 250px;
}

th:has(.log_table_header.log_result) {
    width: 150px;
}

.log_table tbody tr {
    font-size: 16px;
    height: 42px;
}

.log_pagination {
    display: inline-table;
}

.log_pagination ul li {
    text-align: center;
}

.log_pagination ul li.page-left,
.log_pagination ul li.page-right {
    width: 50px;
    height: 50px;
    padding-top: 12px;
}

.page-item:not(:first-child) .page-link {
    margin-left: 5px !important;
}

.page-link-left,
.page-link-right {
    text-decoration: unset;
}

.log_pagination ul li a {
    width: 40px;
    height: 40px;
    padding: 7px 9px;
    margin: 5px;
    border: unset;
    border-radius: 50%;
    font-size: 18px;
}

.log_pagination ul li.active {
    --bs-pagination-active-bg: #5fc7bc;
    --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgb(95 199 188 / 25%) !important;
    font-weight: bold;
}

.react-datepicker-wrapper {
    width: fit-content !important;
}

/*---------------------------------------------------
    SPEAKER_LIST STYLE
-----------------------------------------------------*/

.speaker_list_area {
    width: 100%;
}

.speaker_list {
    margin-top: 10px;
    overflow-y: scroll;
}
.speaker_list::-webkit-scrollbar {
    width: 5px;
}

.speaker_list_top {
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    height: 45px;
    padding: 10px 15px;
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    background: #f7f7f7;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.speaker_list_top > div {
    width: 40px;
    height: 40px;
    padding-left: 15px;
    cursor: pointer;
    z-index: 10;
}

.speaker_list table {
    border-collapse: separate;
    border-spacing: 0;
    vertical-align: middle;
}

.speaker_list table thead {
    position: sticky;
    top: 45px;
    background: #f7f7f7;
    border-collapse: separate;
}

.speaker_list table th img {
    width: 30px;
    cursor: pointer;
}

.speaker_list table tbody {
    font-size: 18px;
}

.speaker_list table tbody tr td:nth-child(4) {
    width: 200px;
}

.speaker_list table tbody tr td:nth-child(5) {
    width: 300px;
}

.speaker_list_btns {
    display: flex;
    justify-content: center;
}
.speaker_list_btns input {
    font-size: 18px;
    text-align: center;
    width: 50px;
    border-radius: 0;
    border-left: none;
    border-right: none;
}
.speaker_list_btns input:disabled {
    background-color: #ffffff;
}
.speaker_list_btns span {
    width: 30px;
    font-size: 20px;
    padding: 15px 0;
}
.speaker_list_btns button {
    padding: 5px;
    width: 41px;
    height: 41px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 0;
}

.speaker_list_btns button:hover {
    border: 1px solid #afafaf;
}

.today_set_btn {
    position: absolute;
    right: 340px;
    top: 6px;
}

.time_set_pop_btn.disabled > g > g > path:nth-child(2) {
    fill: #d9d9d9;
}

.schedule_alert_modal_body {
    width: 650px;
}

.exclamation {
    width: 50px;
    margin-right: 15px;
}

.spicon_setting > g > circle {
    fill: #38b8ab;
}

.z-1 {
    z-index: 1;
}

.z-2 {
    z-index: 2;
}
